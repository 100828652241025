import { template as template_e8d7c3fb6f5d4cc9968eae339418fca9 } from "@ember/template-compiler";
const FKText = template_e8d7c3fb6f5d4cc9968eae339418fca9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
