import { template as template_762ca915da3043b09a28433116cd2622 } from "@ember/template-compiler";
const FKControlMenuContainer = template_762ca915da3043b09a28433116cd2622(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
