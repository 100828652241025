import { template as template_9647c1b56b844a09afe6e4d9600d708b } from "@ember/template-compiler";
const SidebarSectionMessage = template_9647c1b56b844a09afe6e4d9600d708b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
